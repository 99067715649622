<template>
  <div class="basic-component">
      <!-- 订单号/状态 -->
      <div v-if="showHeader" class="orderTitle-id mb-8">
          <span>
              <span class="blod">{{details.orderType === 3? details.appOrderId: details.dcosOrderId}}</span>
              <span v-if="[1,2,5].includes(details.orderType)" class="bigTye">
                {{ details.orderType===1 ? $t('大客户') : details.orderType===2 ? $t('试驾车') : $t('大客户试驾车') }}
              </span>
              <span v-if="isRisk" class="risk">{{ $t('风险') }}</span>
              <!-- <span v-if="isLocked" class="risk">{{ $t('锁定') }}</span> -->
              <!-- lockStatus 锁定状态 0未定 2质损 4 冻结 6都  -->
              <!-- portalLockStatus 总部锁定 0未锁定 1锁定 2解锁 -->
              <span v-if="details?.portalLockStatus === 1" class="risk">{{ $t('锁单') }}</span>
              <span v-if="[2, 6].includes(details?.lockStatus)" class="risk">{{ $t('质损') }}</span>
              <span v-if="[4, 6].includes(details?.lockStatus)" class="risk">{{ $t('车辆冻结') }}</span>
          </span>
          <span v-if="showDeliveryCostStatus" class="status">
              {{ details.deliveryCostStatus | codeFormat }}
          </span>
          <span v-else-if="!showDeliveryOffsiteStatus" class="status">
              {{ details.deliveryStatus | codeFormat }}
          </span>
          <span v-else class="status">
            <span v-if="details.deliveryOffsiteStatus">{{ details.deliveryOffsiteStatus | codeFormat }}</span>
            <span v-else>{{ details.deliveryStatus | codeFormat }}</span>
          </span>
      </div>
      <!-- 客户姓名 -->
      <div v-if="details.customerName" class="name mb-8">
          {{ details.customerName }}
      </div>
      <!-- 个人/企业  分期/全款 -->
      <div class="tag mb-8">
          <div>
              {{ details.customerType == 1 ? $t('个人') : $t('企业') }}
          </div>
          <template v-if="[1, 0].includes(details.installmentsFlag)">
              <div class="gap"></div>
              <div>
                  {{ details.installmentsFlag == 1 ? $t('分期') : $t('全款') }}
              </div>
          </template>
          <template v-if="details.lossStatus === 1">
              <div class="gap"></div>
              <div>
                  {{ $t('质损车') }}
              </div>
          </template>
          <template v-if="details?.isExhibition === 1">
              <div class="gap"></div>
              <div>
                  {{ $t('展车') }}
              </div>
          </template>
          <template v-if="details?.pdiStatus === '4004001'">
            <div class="gap"></div>
            <div>
                PDI{{ details?.pdiStatus | codeFormat }}
            </div>
          </template>
      </div>
      <!-- 车系/车型/外饰/内饰 -->
      <div class="gray-font mb-8">
          <div class="mb-8">
              {{ details.carOrderMaterial?.seriesName }} |
              {{ details.carOrderMaterial?.modelName }} |
              {{ details.carOrderMaterial?.colourName }} |
              {{ details.carOrderMaterial?.interiorName }} 
          </div>
          <div class="mb-8">VIN: {{ details.vin || '--'  }}</div>
          <!-- 是否授权 -->
          <div v-if="isShowAuthDate">{{ $t('授权交付') }}： {{ details.authDate }}</div>
      </div>
      <ul v-if="showLabels" class="options mb-8">
          <li
              v-if="details.balancePayStatus && calculateCode(details.balancePayStatus)"
              class="li"
              :class="{ 'li-4011003': details.balancePayStatus == 4011003 }"
          >
              {{ details.balancePayStatus | codeFormat }}
          </li>
          <li
              v-if="details.invoiceStatus && calculateCode(details.invoiceStatus)"
              class="li"
              :class="{ 'li-202402': details.invoiceStatus == 202402 }"
          >
              {{ details.invoiceStatus | codeFormat }}
          </li>
          <li
              v-if="details.financeStatus && calculateCode(details.financeStatus)"
              class="li "
              :class="{ 'li-40050012': details.financeStatus == 40050012 }"
          >
              {{ details.financeStatus | codeFormat }}
          </li>

          <li v-if="details?.insureStatus" class="li 4002 insureStatus">{{ details.insureStatus | insuranceCodeFormat }}</li>
      </ul>
  </div>
</template>
<script>
import { calculateCode } from '@/utils/index.js'
import { mapGetters } from 'vuex'
export default {
  props: {
    details:{
      type: Object,
      default:()=>({})
    },
    showLabels: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDeliveryOffsiteStatus: {
      type:Boolean,
      default:false
    },
    showDeliveryCostStatus: {
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      calculateCode
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    isShowAuthDate(){
      const { id } = this.userInfo
      const { authDate, authedDeliveryUserId, deliveryUserId } = this.details || {}
      return authDate && authedDeliveryUserId !== '0' && [deliveryUserId,authedDeliveryUserId].includes(id)
    },
    isRisk(){
      return (this.details?.orderLabel || []).includes('4009001')
    }
  },
  methods:{
  }
}
</script>
<style lang="less" scoped>
.basic-component{
  .mb-8{
      margin-bottom: 8px;
  }
  .gray-font{
      color: gray;
  }
  .orderTitle-id {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
      line-height: 16px;
      .blod {
          flex: 1;
      }
      .bigTye,.risk{
          display: inline-block;
          height: 22px;
          line-height: 22px;
          padding: 0 8px;
          color: #fff;
          margin-left: 8px;
      }
      .bigTye{
          background: #029640;
      }
      .risk{
          background: #E4002C;
      }

      .status {
          font-size: 14px;
          color: #029640;
      }
  }
  .tag {
      display: flex;
      align-items: center;
      color: #b9921a;
  }
  .gap {
      width: 1px;
      margin: 0 8px;
      height: 12px;
      background-color: #d9d9d9;
  }
  .options {
      display: flex;
      flex-wrap: wrap;
      margin-left: -4px;
      margin-right: -4px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
      .li {
          padding: 2px 8px;
          border-radius: 4px;
          border-width: 1px;
          border-style: solid;
          margin-top: 8px;
          margin-left: 4px;
          line-height: 16px;
          font-size: 12px;
          background: rgba(102,102,102,0.1);
          border: 1px solid rgba(102,102,102,0.1);
          &-4011003 {
              border-color: rgba(255, 125, 0, 0.1);
              color: #ff7d00;
              background: rgba(255, 125, 0, 0.1);
          }

          &-202402 {
              color: #029640;
              background: rgba(2, 150, 64, 0.1);
              border-color: rgba(2, 150, 64, 0.1);
          }
          &-40050012 {
              color: #b9921a;
              background: rgba(185, 146, 26, 0.1);
              border-color: rgba(185, 146, 26, 0.1);
          }
          &:last-child {
              margin-right: 4px;
          }
      }
  }
}
</style>